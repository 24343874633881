import { useState, useEffect } from 'react';

class TeamStore {
  public static profiles: any[] = [];
}

const images = [
  {
    name: "Luc",
    url: "Luc.jpeg",
    role: "Zwemonderwijzer"
  },
  {
    name: "Maarten",
    url: "Maarten.jpeg",
    role: "Zwemonderwijzer"
  },
  {
    name: "Ashley",
    url: "Ashley.jpeg",
    role: "Zwemonderwijzeres"
  },
  {
    name: "Rules",
    url: "Rules.jpeg",
    role: "Zwemonderwijzer",
    link: "https://www.b-better.nu/",
    description: "Ben je nog op zoek naar een bedrijf dat bubbelballen of dergelijk producten verhuurt? Dan ben je bij mij aan het goede adres. Tevens organiseren wij ook kinderfeestjes op maat!"
  },
  {
    name: 'Juno',
    url: 'Juno.jpeg',
    role: "Zwemonderwijzer"
  },
  {
    name: 'Lisa',
    url: 'Lisa.jpeg',
    role: "Zwemonderwijzeres"
  },
  {
    name: 'Stefan',
    url: 'Stefan.jpeg',
    role: "Zwemonderwijzer"
  },
  {
    name: 'Edwin',
    url: 'Edwin.jpeg',
    role: "Zwemonderwijzer"
  }];

export function useTeam() {
  const [profiles, setProfiles] = useState<any[]>();

  useEffect(() => {

    if (TeamStore.profiles.length === 0) {
      const _profiles = images.map(i => ({
        name: i.name,
        avatar: "/assets/team/" + i.url,
        role: i.role
      }));

      TeamStore.profiles = _profiles;
      setProfiles(_profiles);
    }
    else {
      setProfiles(TeamStore.profiles);
    }
  }, []);

  return { profiles };
}